import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
})
export class ConfirmDialogComponent implements OnInit {
  lang = 'sl';
  faClose = faTimes;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private ref: MatDialogRef<any>
  ) {
    this.lang = data.lang;
  }

  ngOnInit(): void {}

  submit(): void {
    this.ref.close(true);
  }

  close(): void {
    this.ref.close(false);
  }
}
