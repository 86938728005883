<div>
  <div class="my-10">
    <div class="mb-10 text-center">
      <div class="text-primary mb-10">
        <img
          src="/assets/images/completed_image.png"
          class="mx-auto"
          alt="Completed"
          style="max-width: 180px"
        />

        <div class="mt-8">
          <p class="font-semibold text-xl">
            {{
              lang === "en"
                ? "Results successfully stored!"
                : lang === "de"
                ? "Ergebnisse erfolgreich gespeichert!"
                : lang === "hr"
                ? "Rezultati uspješno pohranjeni!"
                : lang === "pt"
                ? "Resultados armazenados com sucesso!"
                : lang === "sr"
                ? "Rezultati su uspešno sačuvani!"
                : lang === "es"
                ? "¡Resultados almacenados exitosamente!"
                : "Vprašalnik uspešno oddan!"
            }}
          </p>
        </div>
      </div>
    </div>

    <ng-container *ngIf="whitelabel() === 'quantifly'">
      <h3 class="text-base text-center">
        {{
          lang === "en"
            ? "Thank you for submitting the questionnaire. By doing so, you have helped paint a clearer picture of the company's culture."
            : lang === "de"
            ? "Vielen Dank für das Ausfüllen des Fragebogens. Sie haben dadurch zu einem klareren Bild der Unternehmenskultur beigetragen."
            : lang === "hr"
            ? "Hvala što ste odgovorili na upitnik. Čineći to, pomogli ste u stvaranju jasnije slike kulture tvrtke."
            : lang === "pt"
            ? "Obrigado por enviar o questionário. Ao fazer isso, você ajudou a traçar um quadro mais claro da cultura da empresa."
            : lang === "sr"
            ? "Hvala Vam što ste popunili upitnik. Time ste pomogli da se stvori jasnija slika o kulturi kompanije."
            : lang === "es"
            ? "Gracias por enviar el cuestionario. Al hacerlo, has ayudado a pintar una imagen más clara de la cultura de la empresa."
            : "Hvala za oddan vprašalnik. S tem ste pripomogli k izrisu jasneše slike kulture podjetja."
        }}
      </h3>
    </ng-container>

    <ng-container *ngIf="whitelabel() === 'profil'">
      <h3 class="text-base text-center">
        {{
          lang === "en"
            ? "Thank you for submitting the questionnaire. This has contributed to a clearer picture of the employee's competency levels."
            : lang === "de"
            ? "Vielen Dank für das Ausfüllen des Fragebogens. Sie haben dadurch zu einem klareren Bild der Unternehmenskultur beigetragen."
            : lang === "hr"
            ? "Hvala vam na ispunjenom upitniku. Time ste pridonijeli jasnijem prikazu razine kompetencija zaposlenika."
            : lang === "pt"
            ? "O questionário é anônimo. Comentários e avaliações não podem ser vinculados a você"
            : lang === "sr"
            ? "Hvala Vam što ste popunili upitnik. Time ste pomogli da se stvori jasnija slika o kulturi kompanije."
            : lang === "es"
            ? "Gracias por enviar el cuestionario. Al hacerlo, has ayudado a pintar una imagen más clara de la cultura de la empresa."
            : "Hvala za oddan vprašalnik. S tem ste pripomogli k izrisu jasnejše slike o izraženosti kompetenc zaposlenega."
        }}
      </h3>
    </ng-container>
  </div>

  <div class="flex flex-row justify-between items-center">
    <div
      class="bg-primary-transparent rounded-full px-4 py-2 text-primary text-sm"
    >
      <fa-icon [icon]="faCheck" class="mr-2"></fa-icon>
      <span class="text-primary">{{
        lang === "en"
          ? "Saved successfully"
          : lang === "de"
          ? "Erfolgreich gespeichert!"
          : lang === "hr"
          ? "Uspješno spremljeno"
          : lang === "pt"
          ? "Salvo com sucesso"
          : lang === "sr"
          ? "Uspešno sačuvano"
          : lang === "es"
          ? "Guardado exitosamente"
          : "Uspešno shranjeno"
      }}</span>
    </div>

    <button (click)="close()" class="btn btn-primary">
      {{
        lang === "en"
          ? "Close"
          : lang === "de"
          ? "Schließen"
          : lang === "hr"
          ? "Zatvoriti"
          : lang === "sr"
          ? "Zatvori"
          : lang === "pt"
          ? "Fechar"
          : lang === "es"
          ? "Cerrar"
          : "Zapri"
      }}
    </button>
  </div>
</div>
