import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-person-autocomplete-option',
  templateUrl: './person-autocomplete-option.component.html',
  styleUrls: ['./person-autocomplete-option.component.scss']
})
export class PersonAutocompleteOptionComponent implements OnInit {

  @Input('person') person;

  constructor() { }

  ngOnInit(): void {
  }

}
