<div>
  <div class="flex flex-row justify-between items-center">
    <h2 class="text-xl font-bold text-primary">
      {{
        lang === "en"
          ? "Are you sure you want to finish?"
          : lang === "de"
          ? "Sind Sie sicher, dass Sie beenden wollen?"
          : lang === "hr"
          ? "Jeste li sigurni da želite završiti?"
          : lang === "pt"
          ? "Tem certeza que deseja terminar?"
          : lang === "es"
          ? "¿Estás seguro/a de que quieres finalizar?"
          : lang === "sr"
          ? "Da li ste sigurni da želite da završite?"
          : "Zaključi vprašalnik?"
      }}
    </h2>

    <button
      class="btn btn-sm btn-empty text-base border-none transition-all hover:opacity-60"
      (click)="close()"
    >
      <fa-icon [icon]="faClose"></fa-icon>
    </button>
  </div>

  <div class="mb-10 mt-6">
    <h3 class="text-base">
      {{
        lang === "en"
          ? "You will not be able to modify your answers once you finish."
          : lang === "de"
          ? "Sobald Sie fertig sind, können Sie Ihre Antworten nicht mehr ändern."
          : lang === "hr"
          ? "Nećete moći mijenjati svoje odgovore nakon što završite."
          : lang === "sr"
          ? "Nećete biti u mogućnosti da promenite Vaše odgovore jednom kada završite."
          : lang === "pt"
          ? "Você não poderá modificar as suas respsotas assim que terminar."
          : lang === "es"
          ? "No podrás modificar tus respuestas una vez que termines."
          : "Kasneje ni več mogoče spreminjati odgovorov."
      }}
    </h3>
  </div>

  <div class="flex flex-row justify-between">
    <button
      class="btn btn-gray-light-outline bg-white !border-gray-light !text-gray-dark drop-shadow-sm"
      (click)="close()"
    >
      {{
        lang === "en"
          ? "Cancel"
          : lang === "de"
          ? "Abbrechen"
          : lang === "hr"
          ? "Odustani"
          : lang === "pt"
          ? "Cancelar"
          : lang === "es"
          ? "Cancelar"
          : lang === "sr"
          ? "Otkazati"
          : "Prekliči"
      }}
    </button>
    <button class="btn btn-primary" (click)="submit()">
      {{
        lang === "en"
          ? "Finish"
          : lang === "de"
          ? "Beenden"
          : lang === "hr"
          ? "Završi"
          : lang === "sr"
          ? "Završi"
          : lang === "pt"
          ? "Finalizar"
          : lang === "es"
          ? "Finalizar"
          : "Zaključi"
      }}
    </button>
  </div>
</div>
