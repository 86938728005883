<div
  *ngIf="showBannerEnvironment() === 'local'"
  class="flex items-center justify-center p-0.5 fixed top-0 w-full bg-red text-white text-[10px] text-center font-bold mx-auto"
  style="z-index: 1000000000000"
>
  LOCAL
</div>
<div
  *ngIf="showBannerEnvironment() === 'beta'"
  class="flex items-center justify-center p-0.5 fixed top-0 w-full bg-green text-white text-[10px] text-center font-bold mx-auto"
  style="z-index: 1000000000000"
>
  BETA
</div>

<nav class="flex justify-center items-center">
  <div class="mx-auto">
    <span
      class="hover:pointer block py-5 px-3 cursor-pointer hover:opacity-70 transition-all"
      (click)="reload()"
    >
      <ng-container *ngIf="whitelabel() === 'quantifly'">
        <svg
          width="124"
          height="30"
          viewBox="0 0 124 30"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0)">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M18.7657 18.7657C19.3027 18.2288 19.7605 17.6427 20.1391 17.0219L22.9701 17.7804C22.41 18.8579 21.6778 19.8691 20.7735 20.7734C19.691 21.8559 18.4554 22.6918 17.1363 23.2811L16.389 20.492C17.2458 20.0574 18.0495 19.4819 18.7657 18.7657ZM11.7485 24.3304C8.77575 24.2283 5.83335 23.0426 3.56416 20.7734C-1.18805 16.0212 -1.18805 8.31639 3.56416 3.56416C8.31639 -1.18805 16.0212 -1.18805 20.7735 3.56416C23.2279 6.01857 24.4146 9.26059 24.3338 12.4766L21.4872 11.7139C21.3788 9.48013 20.4717 7.27787 18.7657 5.57191C15.1224 1.92855 9.21528 1.92855 5.57191 5.57191C1.92855 9.21528 1.92855 15.1224 5.57191 18.7657C7.08776 20.2816 8.99548 21.1667 10.969 21.4212L11.7485 24.3304Z"
              fill="url(#paint0_linear)"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M13.4529 15.0207C13.2913 14.4175 13.8433 13.8655 14.4465 14.0271L25.9833 17.1184C26.5864 17.28 26.7884 18.0341 26.3469 18.4757L17.9014 26.9212C17.4598 27.3626 16.7058 27.1607 16.5442 26.5573L13.4529 15.0207ZM15.1814 11.2845L26.718 14.3758C29.4325 15.1031 30.3418 18.4962 28.3546 20.4834L19.9092 28.9288C17.922 30.9161 14.5289 30.0068 13.8015 27.2923L10.7103 15.7556C9.98294 13.0411 12.4669 10.5572 15.1814 11.2845Z"
              fill="url(#paint1_linear)"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M13.4529 15.0207C13.2913 14.4175 13.8433 13.8655 14.4465 14.0271L25.9833 17.1184C26.5864 17.28 26.7884 18.0341 26.3469 18.4757L17.9014 26.9212C17.4598 27.3626 16.7058 27.1607 16.5442 26.5573L13.4529 15.0207ZM15.1814 11.2845L26.718 14.3758C29.4325 15.1031 30.3418 18.4962 28.3546 20.4834L19.9092 28.9288C17.922 30.9161 14.5289 30.0068 13.8015 27.2923L10.7103 15.7556C9.98294 13.0411 12.4669 10.5572 15.1814 11.2845Z"
              fill="black"
              fill-opacity="0.1"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M21.4883 11.7137C21.3799 9.47993 20.4727 7.27768 18.7668 5.57171C15.1234 1.92835 9.21631 1.92835 5.57297 5.57171C4.00728 7.1374 3.11444 9.1211 2.89441 11.1636C3.50964 5.19565 8.09418 0.55957 13.6585 0.55957C15.7573 0.55957 17.7168 1.21922 19.3764 2.36116C19.8648 2.72099 20.3324 3.12192 20.7745 3.56396C23.2289 6.01837 24.4157 9.26039 24.3348 12.4764L21.4883 11.7137ZM11.7476 24.3229L10.97 21.421C8.99651 21.1665 7.0888 20.2814 5.57297 18.7655C3.57024 16.7628 2.6684 14.0761 2.86743 11.4573C2.83883 11.812 2.82422 12.1711 2.82422 12.5341C2.82422 18.4267 6.67534 23.3253 11.7476 24.3229ZM20.7745 20.7733C21.6788 19.869 22.411 18.8577 22.9712 17.7803L20.1402 17.0217C19.7615 17.6425 19.3037 18.2286 18.7668 18.7655C18.0505 19.4818 17.2467 20.0572 16.3899 20.4918L17.1372 23.281C18.4563 22.6917 19.692 21.8557 20.7745 20.7733Z"
              fill="black"
              fill-opacity="0.1"
            />
            <path
              d="M47.125 9.83676V24.6218H44.4883V18.9431C44.0963 19.5515 43.5894 20.018 42.9674 20.3425C42.3589 20.6535 41.6489 20.809 40.8379 20.809C39.8102 20.809 38.9042 20.5791 38.1201 20.1194C37.3494 19.6462 36.7478 18.9904 36.3152 18.1521C35.8825 17.3003 35.666 16.3268 35.666 15.2316C35.666 14.1499 35.8755 13.1967 36.2947 12.3719C36.7274 11.5336 37.3292 10.8846 38.0999 10.4249C38.884 9.96519 39.7763 9.73535 40.7769 9.73535C41.6016 9.73535 42.325 9.89759 42.947 10.2221C43.5689 10.5466 44.0828 11.0131 44.4883 11.6215V9.83676H47.125ZM40.8883 18.5983C41.7943 18.5983 42.5311 18.2941 43.099 17.6856C43.6804 17.0637 43.9711 16.2659 43.9711 15.2924C43.9711 14.3189 43.6804 13.5279 43.099 12.9195C42.5311 12.2976 41.7943 11.9866 40.8883 11.9866C39.9824 11.9866 39.2456 12.2976 38.6777 12.9195C38.11 13.5279 37.8193 14.3189 37.8056 15.2924C37.8193 16.2659 38.11 17.0637 38.6777 17.6856C39.2456 18.2941 39.9824 18.5983 40.8883 18.5983Z"
              fill="#272727"
            />
            <path
              d="M60.1196 9.83691V20.6874H57.4628V18.6796C56.7327 20.0857 55.4753 20.7888 53.6905 20.7888C52.4734 20.7888 51.5134 20.417 50.8105 19.6734C50.1073 18.9297 49.7559 17.9157 49.7559 16.6312V9.83691H52.4127V15.8605C52.4127 16.6176 52.6154 17.2126 53.0212 17.6452C53.4402 18.0779 54.0148 18.2942 54.7449 18.2942C55.5834 18.2807 56.2459 17.9833 56.7327 17.4019C57.2194 16.8204 57.4628 16.0836 57.4628 15.1912V9.83691H60.1196Z"
              fill="#272727"
            />
            <path
              d="M69.4127 20.6873V19.369C69.0341 19.8422 68.5474 20.2005 67.9523 20.4439C67.3574 20.6873 66.6747 20.809 65.904 20.809C65.1468 20.809 64.4774 20.667 63.8961 20.3831C63.3281 20.0856 62.8887 19.68 62.5779 19.1662C62.2668 18.6524 62.1113 18.0777 62.1113 17.4422C62.1113 16.4282 62.4695 15.6372 63.1862 15.0694C63.9028 14.4879 64.9168 14.1905 66.2283 14.177H69.3923V13.893C69.3923 13.2575 69.1828 12.764 68.7636 12.4125C68.3443 12.0609 67.7361 11.8852 66.9382 11.8852C65.9107 11.8852 64.8359 12.2232 63.7135 12.8992L62.7602 11.0739C63.6121 10.6007 64.3828 10.2627 65.0723 10.0598C65.7755 9.84351 66.5935 9.73535 67.5263 9.73535C68.9327 9.73535 70.021 10.0734 70.7917 10.7494C71.5761 11.4255 71.9749 12.3719 71.9883 13.5888L72.0087 20.6873H69.4127ZM66.5327 18.7808C67.2897 18.7808 67.9388 18.6051 68.4796 18.2535C69.0204 17.8884 69.3248 17.4422 69.3923 16.9149V15.9414H66.6543C65.9513 15.9414 65.4307 16.0496 65.0927 16.2659C64.7681 16.4687 64.606 16.8068 64.606 17.28C64.606 17.7397 64.7749 18.1048 65.1129 18.3752C65.4646 18.6456 65.9376 18.7808 66.5327 18.7808Z"
              fill="#272727"
            />
            <path
              d="M81.0912 9.73535C82.3352 9.73535 83.3154 10.1139 84.032 10.8711C84.7487 11.6147 85.1068 12.6288 85.1068 13.9133V20.6873H82.45V14.684C82.45 13.9268 82.2338 13.3319 81.8012 12.8992C81.3685 12.453 80.7801 12.23 80.0366 12.23C79.1578 12.2435 78.4613 12.5409 77.9477 13.1223C77.4473 13.7037 77.1972 14.4474 77.1972 15.3533V20.6873H74.5605V9.83676H77.1972V11.8649C77.941 10.4722 79.2387 9.76238 81.0912 9.73535Z"
              fill="#272727"
            />
            <path
              d="M94.0115 20.0182C93.1464 20.5455 92.2404 20.8092 91.2939 20.8092C90.361 20.8092 89.5971 20.5387 89.002 19.9979C88.4072 19.4435 88.1097 18.6323 88.1097 17.5641V12.2099H86.5885L86.5684 10.2831H88.1097V7.30176H90.7259V10.2831H93.8291V12.2099H90.7259V17.0977C90.7259 17.5979 90.8206 17.9562 91.0099 18.1726C91.1992 18.3754 91.4832 18.4768 91.8618 18.4768C92.2673 18.4768 92.7812 18.3281 93.4032 18.0306L94.0115 20.0182Z"
              fill="#272727"
            />
            <path
              d="M95.6754 9.83757H98.3121V20.6881H95.6754V9.83757ZM96.9734 5.17285C97.4061 5.17285 97.7643 5.32158 98.0483 5.61905C98.3322 5.91649 98.4742 6.29508 98.4742 6.75481C98.4742 7.20098 98.3322 7.5728 98.0483 7.87027C97.7643 8.16773 97.4061 8.31646 96.9734 8.31646C96.5408 8.31646 96.1826 8.16773 95.8986 7.87027C95.6146 7.5728 95.4727 7.20098 95.4727 6.75481C95.4727 6.29508 95.6146 5.91649 95.8986 5.61905C96.1826 5.32158 96.5408 5.17285 96.9734 5.17285Z"
              fill="#272727"
            />
            <path
              d="M105.694 7.78907C105.288 7.78907 104.95 7.904 104.68 8.13386C104.423 8.36372 104.294 8.70849 104.294 9.16822V10.2837H107.012V12.2104H104.294V20.688H101.658V12.2104H100.238V10.2837H101.658V9.24934C101.658 8.4516 101.834 7.77557 102.185 7.22119C102.537 6.65331 103.003 6.22741 103.585 5.94347C104.18 5.65953 104.822 5.51758 105.512 5.51758C106.188 5.51758 106.85 5.67306 107.499 5.98405L107.033 8.11359C106.492 7.89725 106.046 7.78907 105.694 7.78907Z"
              fill="#272727"
            />
            <path
              d="M108.273 5.63867H110.91V20.6874H108.273V5.63867Z"
              fill="#272727"
            />
            <path
              d="M118.533 22.0463C118.195 22.9387 117.708 23.608 117.072 24.0542C116.45 24.5139 115.707 24.7437 114.842 24.7437C114.314 24.7437 113.834 24.6626 113.402 24.5003C112.982 24.3516 112.563 24.1082 112.144 23.7702L113.28 21.7624C113.537 21.9516 113.773 22.0869 113.99 22.168C114.206 22.2626 114.436 22.3099 114.679 22.3099C115.396 22.3099 115.923 21.9449 116.261 21.2148L116.586 20.5049L112.104 9.83691H114.842L117.924 17.7263L120.743 9.83691H123.4L118.533 22.0463Z"
              fill="#272727"
            />
          </g>
          <defs>
            <linearGradient
              id="paint0_linear"
              x1="3.88337"
              y1="2.66648"
              x2="24.976"
              y2="24.5703"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#2DE7C5" />
              <stop offset="1" stop-color="#169FFF" />
            </linearGradient>
            <linearGradient
              id="paint1_linear"
              x1="4.28886"
              y1="2.66679"
              x2="24.5702"
              y2="24.5706"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#2DE7C5" />
              <stop offset="1" stop-color="#169FFF" />
            </linearGradient>
            <clipPath id="clip0">
              <rect width="123.621" height="30" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </ng-container>
      <ng-container *ngIf="whitelabel() === 'profil'">
        <img
          src="/assets/images/profil/img_profil.png"
          alt="profil"
          class="h-10"
        />
      </ng-container>
    </span>
  </div>
</nav>
