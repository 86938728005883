import { Component, Inject, OnInit } from '@angular/core';
import { faTimes, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons/faCheckCircle';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-complete-dialog',
  templateUrl: './complete-dialog.component.html',
  styleUrls: ['./complete-dialog.component.scss'],
})
export class CompleteDialogComponent implements OnInit {
  lang = 'sl';
  faClose = faTimes;
  faArrowRight = faArrowRight;
  faCheck = faCheckCircle;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private ref: MatDialogRef<any>
  ) {
    this.lang = data.lang;
  }

  whitelabel() {
    return environment?.whitelabel || 'quantifly';
  }

  ngOnInit(): void {}

  close(): void {
    this.ref.close(false);
  }
}
