import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { ScrollToTopService } from './services/scroll-to-top.service';
import { Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements AfterViewInit {
  @ViewChild('pageWindow') pageWindow;

  constructor(
    private scrollToTopService: ScrollToTopService,
    private title: Title
  ) {
    if (environment?.whitelabel === 'profil') {
      this.title.setTitle('Profil');
    } else {
      this.title.setTitle('Quantifly');
    }
  }

  ngAfterViewInit(): void {
    this.scrollToTopService.getObservable().subscribe(() => this.scrollToTop());
  }

  private scrollToTop(): void {
    this.pageWindow.nativeElement.scrollTop = 0;
  }
}
