<button
  class="btn btn-primary-outline relative group"
  (click)="toggle.emit()"
  [ngClass]="mergeClasses()"
  [disabled]="isDisabled"
>
  <span
    class="absolute text-primary ml-3 mt-3 block top-0 left-0 group-hover:text-primary-hover ease-in-out duration-150"
    *ngIf="isSelected"
  >
    <fa-icon [icon]="faCheck"></fa-icon>
  </span>

  <span class="ml-6 mr-6 block text-left">
    <ng-content></ng-content>
  </span>

  <span
    class="absolute text-red mr-3 mt-3 block top-0 right-0 group-hover:text-red-hover ease-in-out duration-150"
    *ngIf="isSelected"
  >
    <fa-icon [icon]="faTimes"></fa-icon>
  </span>
</button>
