import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ScrollToTopService {

  private observable = new Subject();

  public getObservable(): Observable<any> {
    return this.observable;
  }

  public scrollToTop(): void {
    this.observable.next();
  }
}
