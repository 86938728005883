import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { environment } from 'src/environments/environment';

const routes: Routes = [
  {
    path: 'q',
    loadChildren: () =>
      import('./questionnaire/questionnaire.module').then(
        (m) => m.QuestionnaireModule
      ),
  },
  {
    path: '**',
    redirectTo: (() => {
      if (environment?.whitelabel === 'profil') {
        return '/q/profil';
      } else {
        return '/q/test';
      }
    })(),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
