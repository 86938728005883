import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {faCheck, faTimes} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-toggleable-button',
  templateUrl: './toggleable-button.component.html',
  styleUrls: ['./toggleable-button.component.scss'],
})
export class ToggleableButtonComponent {

  faCheck = faCheck;
  faTimes = faTimes;

  @Input() classes = {};
  @Input() isSelected = false;
  @Input() isDisabled = false;

  @Output() toggle = new EventEmitter();

  mergeClasses(): object {
    const combine = {
      'btn-primary-outline': !this.isSelected,
      'btn-primary-selected': this.isSelected
    };

    return {...this.classes, ...combine};
  }
}
