import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavbarComponent } from './components/navbar/navbar.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ToggleableButtonComponent } from './components/toggleable-button/toggleable-button.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxStarsModule } from 'ngx-stars';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { HttpClientModule } from '@angular/common/http';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import { PasswordDialogComponent } from './dialogs/password-dialog/password-dialog.component';
import { ConfirmDialogComponent } from './dialogs/confirm-dialog/confirm-dialog.component';
import { CompleteDialogComponent } from './dialogs/complete-dialog/complete-dialog.component';
import { PersonAutocompleteOptionComponent } from './partials/person-autocomplete-option/person-autocomplete-option.component';

@NgModule({
  declarations: [
    NavbarComponent,
    ToggleableButtonComponent,
    PasswordDialogComponent,
    ConfirmDialogComponent,
    CompleteDialogComponent,
    PersonAutocompleteOptionComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    NgxStarsModule,
    MatAutocompleteModule,
    ScrollingModule,
    DragDropModule,
    MatDialogModule,
    MatTooltipModule,
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NavbarComponent,
    ToggleableButtonComponent,
    FontAwesomeModule,
    NgxStarsModule,
    MatAutocompleteModule,
    ScrollingModule,
    DragDropModule,
    HttpClientModule,
    MatDialogModule,
    MatTooltipModule,
    PersonAutocompleteOptionComponent,
  ],
})
export class SharedModule {}
