<div class="overflow-auto h-auto">
  <div class="d-flex justify-content-between">
    <!-- Image on the left -->
    <div class="mx-auto mb-4" style="height: 160px">
      <img
        src="/assets/images/password_image.png"
        class="h-full mx-auto"
        alt="Geslo"
      />
    </div>

    <!-- <div class="flex flex-wrap justify-center">
      <ng-container *ngFor="let item of departments; let i = index">
        <a
          [ngClass]="{
            'bg-primary-transparent': department == item.name,
            'bg-white': department != item.name
          }"
          class="hover:bg-gray-200 font-bold py-3 px-6 rounded border border-primary mt-3 md:mt-5 mr-3 md:mr-5 flex content-center gap-3"
          [href]="item.hyperlink"
        >
          {{ item.name }}
        </a>
      </ng-container>
    </div> -->

    <ng-container *ngIf="multiple">
      <div class="flex flex-wrap justify-center">
        <ng-container *ngFor="let item of langs; let i = index">
          <a
            [ngClass]="{
              'bg-primary-transparent': lang === item.lang,
              'bg-white': lang !== item.lang
            }"
            class="hover:bg-gray-200 font-bold py-3 px-6 rounded border border-primary mt-3 md:mt-5 mr-3 md:mr-5 flex content-center gap-3"
            [href]="item.hyperlink"
            [attr.tabindex]="i + 2"
          >
            <img
              [src]="'/assets/images/langs/icn_' + item.lang + '.png'"
              class="h-6"
              [alt]="item.name.toUpperCase()"
            />
            {{ item.name }}
          </a>
        </ng-container>
      </div>
    </ng-container>
  </div>

  <div class="mt-10">
    <h3 class="text-lg font-bold">
      {{
        lang === "en"
          ? "Enter password to unlock"
          : lang === "de"
          ? "Passwort eingeben, um zu entsperren"
          : lang === "hr"
          ? "Unesite lozinku za pristup"
          : lang === "sr"
          ? "Unesite lozinku za otključavanje"
          : lang === "pt"
          ? "Insira a senha para desbloquear"
          : lang === "es"
          ? "Ingresa la contraseña para desbloquear"
          : "Za dostop do vprašalnika vnesite geslo"
      }}
    </h3>
  </div>

  <div class="mb-6 mt-2 relative">
    <fa-icon
      [icon]="faLock"
      class="ml-2 absolute left-2 top-3 text-gray-dark"
    ></fa-icon>
    <input
      (keyup.enter)="submit(password)"
      class="!pl-10"
      type="text"
      [(ngModel)]="password"
      #passwordInput
      [placeholder]="
        lang === 'en'
          ? 'Password...'
          : lang === 'de'
          ? 'Passwort...'
          : lang === 'hr'
          ? 'Lozinka...'
          : lang === 'pt'
          ? 'Senha...'
          : lang === 'sr'
          ? 'Lozinka...'
          : lang === 'es'
          ? 'Contraseña...'
          : 'Geslo...'
      "
      tabindex="0"
      autofocus
    />
  </div>

  <div class="text-center">
    <button
      class="btn btn-primary"
      [disabled]="!password"
      (click)="submit(password)"
    >
      {{
        lang === "en"
          ? "Unlock"
          : lang === "de"
          ? "Entsperren"
          : lang === "hr"
          ? "Otključajte"
          : lang === "sr"
          ? "Otključajte"
          : lang === "pt"
          ? "Desbloquear"
          : lang === "es"
          ? "Desbloquear"
          : "Odklenite"
      }}
    </button>
  </div>
</div>
