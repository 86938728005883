import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavbarComponent {
  constructor() {}

  reload(): void {
    window.location.href = window.location.href;
  }

  showBannerEnvironment() {
    return environment?.navbar || '';
  }

  whitelabel() {
    return environment?.whitelabel || 'quantifly';
  }
}
